<template>
  <div class="guide-cnt15">
    <h6 class="h6">人工服务</h6>
    <div class="cnt">
      <p class="p">微微定为顾客提供优质客户服务；</p>
      <p class="p">客服正常上班时间为：周一至周日 9:00-18:00；其余时间您可以在线留言；</p>
      <p class="p pm">欢迎您通过不同方式联系微微定，让我们为您解决问题或困惑，您也可以随时给予我们您的意见或建议。</p>
      <p class="p">微微定在线客服</p>
      <p class="p">客服电话： 400-6070-818</p>
      <p class="p">微微定客服电话（微信）：17722437419</p>
      <p class="p">微信公众号：微微定（搜索）</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt15',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt15 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
    .pm {
      margin-bottom:30px;
    }
  }
}
</style>
